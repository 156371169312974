import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from './style';

const Logo = props => {
  return (
    <SvgIcon
      viewBox="0 0 232.03 124.88"
      fontSize="inherit"
      classes={useStyles()}
      {...props}
    >
      <g>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M47.85,26.31l-6.49,5.52c-2.35-3.28-5.36-4.92-9.04-4.92c-2.99,0-5.37,0.91-7.12,2.73
		c-1.75,1.82-2.62,4.23-2.62,7.22c0,3.07,0.92,5.54,2.76,7.41c1.84,1.88,4.35,2.81,7.52,2.81c3.39,0,6.01-1.06,7.85-3.19v-1.68
		h-8.33v-8.01h17.64v11.26c-1.66,2.96-4.03,5.35-7.12,7.17c-3.08,1.82-6.56,2.73-10.42,2.73c-5.48,0-10.09-1.77-13.83-5.3
		c-3.73-3.54-5.6-7.94-5.6-13.2c0-5.23,1.87-9.62,5.6-13.18c3.73-3.55,8.34-5.33,13.83-5.33C39.12,18.35,44.24,21,47.85,26.31z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M76.53,54.72l-8.06-11.09h-3.84v11.09h-9.31V19h14.02c4,0,7.31,1.12,9.9,3.35c2.6,2.24,3.9,5.21,3.9,8.93
		c0,4.51-1.88,7.9-5.63,10.17l9.42,13.26H76.53z M64.63,35.51h4.76c1.3,0,2.34-0.39,3.11-1.16c0.78-0.78,1.16-1.8,1.16-3.06
		c0-1.26-0.39-2.27-1.16-3.03c-0.78-0.76-1.81-1.14-3.11-1.14h-4.76V35.51z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M111.28,51.74H98.45l-1.3,2.98H87.14l16.24-36.37h2.98l16.23,36.37h-10.01L111.28,51.74z M104.89,35.99
		l-3.14,8.28h6.22L104.89,35.99z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M150.52,27.39h-14.94v6.82h12.12v8.39h-12.12v12.12h-9.31V19h24.24V27.39z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M172.76,51.74h-12.83l-1.3,2.98h-10.01l16.24-36.37h2.98l16.24,36.37h-10.01L172.76,51.74z M166.38,35.99
		l-3.14,8.28h6.22L166.38,35.99z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M211.46,25.55l-6.22,4.76c-0.87-1.3-1.75-2.26-2.65-2.87c-0.9-0.61-1.98-0.92-3.25-0.92
		c-2.13,0-3.19,0.76-3.19,2.27c0,0.65,0.23,1.18,0.68,1.6c0.45,0.41,1.25,0.86,2.41,1.33l4.11,1.73c3.1,1.3,5.4,2.79,6.9,4.46
		c1.5,1.68,2.25,3.78,2.25,6.3c0,3.43-1.24,6.14-3.71,8.14c-2.47,2-5.6,3-9.39,3c-3.21,0-6.06-0.72-8.55-2.17
		c-2.49-1.44-4.38-3.39-5.68-5.84l6.28-4.76c2.27,3.07,4.92,4.6,7.95,4.6c2.45,0,3.68-0.87,3.68-2.6c0-0.79-0.25-1.39-0.76-1.79
		c-0.51-0.4-1.46-0.9-2.87-1.52l-3.68-1.62c-6.03-2.63-9.04-6.22-9.04-10.77c0-3.17,1.16-5.73,3.49-7.66
		c2.33-1.93,5.33-2.9,9.01-2.9C204.97,18.35,209.04,20.75,211.46,25.55z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M38.83,76.22c3.28,2.87,4.91,6.7,4.91,11.49c0,4.72-1.65,8.57-4.94,11.54c-3.29,2.97-7.43,4.46-12.4,4.46
		H15.33V71.91H26.4C31.41,71.91,35.56,73.34,38.83,76.22z M23.61,79.42v16.81h2.75c2.6,0,4.74-0.76,6.41-2.29
		c1.67-1.53,2.5-3.6,2.5-6.24c0-2.7-0.83-4.75-2.48-6.17c-1.65-1.41-3.8-2.12-6.43-2.12H23.61z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M55.69,67.6c1,0.95,1.49,2.11,1.49,3.49c0,1.35-0.5,2.5-1.49,3.44c-1,0.95-2.2,1.42-3.61,1.42
		s-2.61-0.47-3.59-1.4c-0.98-0.93-1.47-2.09-1.47-3.47c0-1.38,0.49-2.55,1.47-3.49c0.98-0.95,2.18-1.42,3.59-1.42
		S54.7,66.65,55.69,67.6z M56.27,103.7h-8.29V79.61h8.29V103.7z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M86.38,79.61l-12.33,24.66H71.4L59.07,79.61h8.77l4.91,10.84l4.87-10.84H86.38z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M96.88,67.6c1,0.95,1.49,2.11,1.49,3.49c0,1.35-0.5,2.5-1.49,3.44c-1,0.95-2.2,1.42-3.61,1.42
		s-2.61-0.47-3.59-1.4c-0.98-0.93-1.47-2.09-1.47-3.47c0-1.38,0.49-2.55,1.47-3.49c0.98-0.95,2.18-1.42,3.59-1.42
		S95.89,66.65,96.88,67.6z M97.46,103.7h-8.29V79.61h8.29V103.7z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M123.9,81.71c1.67,1.78,2.5,4.17,2.5,7.15v14.84h-8.29V90.07c0-1.06-0.33-1.92-0.99-2.58
		c-0.66-0.66-1.5-0.99-2.53-0.99c-1.03,0-1.86,0.33-2.51,0.99c-0.64,0.66-0.96,1.52-0.96,2.58v13.63h-8.29V79.61h7.56v1.97
		c1.8-1.7,4.06-2.55,6.79-2.55C120,79.04,122.23,79.93,123.9,81.71z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M156.42,79.61v21.15c0,4.11-1.17,7.17-3.52,9.18c-2.34,2.01-5.48,3.01-9.39,3.01c-4.69,0-8.74-1.45-12.14-4.34
		l4.19-5.83c2.41,1.83,4.77,2.75,7.08,2.75c1.57,0,2.88-0.35,3.93-1.06c1.04-0.71,1.57-1.77,1.57-3.18v-0.87
		c-1.51,1.48-3.61,2.22-6.31,2.22c-3.34,0-6.08-1.08-8.21-3.23c-2.14-2.15-3.2-5.03-3.2-8.62c0-3.6,1.08-6.45,3.23-8.57
		c2.15-2.12,4.88-3.18,8.19-3.18c3.18,0,5.52,1.01,7.03,3.03v-2.46H156.42z M146.91,94.43c0.92-0.91,1.37-2.18,1.37-3.78
		s-0.46-2.8-1.37-3.59c-0.91-0.79-2.01-1.18-3.3-1.18c-1.32,0-2.43,0.42-3.35,1.25c-0.91,0.83-1.37,2.02-1.37,3.56
		c0,1.57,0.47,2.82,1.4,3.73c0.93,0.92,2.04,1.37,3.32,1.37C144.89,95.8,145.99,95.34,146.91,94.43z"
        />
      </g>
      <path
        style={{ fill: '#3284C5' }}
        d="M210.49,112.8l-30.97-16.3c-0.48-0.25-0.95-0.8-0.72-1.69c0,0,3.06-11.45,3.18-11.78
	c0.99-3.06,0.29-4.17-0.63-5.17c-0.94-0.83-2.87-0.78-3.75,1c-0.43,0.66-0.6,2.17,0.37,3.24l0.58,0.72l-2.61,9.96
	c-0.28,1.01-1.26,1.17-1.84,0.86c0,0-11.66-6.17-12.71-6.72c-0.86-0.71-0.94-2.38,0.1-3.57c0,0,21.7-21.99,21.85-22.08
	c0.78-0.75,1.85-0.6,2.26,0.05c0.94,1.69,5.81,10.54,7.92,14.4c0.58,0.99,1.17,2.92,0.79,4.34s-3.06,11.29-3.25,12.25
	c-0.09,0.28-0.32,1.3-0.26,1.83c0.13,1.2,0.98,1.96,2,2.18c3.26,0.61,3.85-3.1,2.24-4.38c-0.04-0.03-0.07-0.06-0.11-0.09
	c-0.41-0.33-0.45-0.74-0.3-1.21c0.07-0.23,1.41-4.74,1.42-4.79c0.48-1.56,2.07-1.6,2.66-0.65l13.51,25.15
	C213.1,112,211.72,113.41,210.49,112.8z"
      />
    </SvgIcon>
  );
};

export default Logo;
